export const GET_ITEMS = 'GET_ITEMS';
export const ADD_CASE = 'ADD_CASE';
export const ADDING_CASE = 'ADDING_CASE';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ITEMS_LOADING = 'ITEMS_LOADING';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const EXISTING_USER_ACCT_RESET = 'EXISTING_USER_ACCT_RESET';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_EXISTING_CUSTOMER_SUCCESS =
  'REGISTER_EXISTING_CUSTOMER_SUCCESS';
export const REGISTER_EXISTING_CUSTOMER_FAIL =
  'REGISTER_EXISTING_CUSTOMER_FAIL';
export const AUTHENTICATION_RESET = 'AUTHENTICATION_RESET';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CREATE_PICKUP = 'CREATE_PICKUP';
export const PICKUP_FAIL = 'PICKUP_FAIL';
export const CREATED_PICKUP = 'CREATED_PICKUP';
export const LOADING_USER_INFO = 'LOADING_USER_INFO';
export const LOAD_USER_INFO = 'LOAD_USER_INFO';
export const LOADED_USER_INFO = 'LOADED_USER_INFO';
export const LOADED_FAIL_USER_INFO = 'LOADED_FAIL_USER_INFO';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const OFFICE_MODIFY_FAIL = 'OFFICE_MODIFY_FAIL';
export const OFFICE_ADD_FAIL = 'OFFICE_ADD_FAIL';
export const LOAD_OFFICEES = 'LOAD_OFFICEES';
export const MODIFIED_OFFICE = 'MODIFIED_OFFICE';
export const LOAD_OFFICEES_FAIL = 'LOAD_OFFICEES_FAIL';
export const LOADED_OFFICEES = 'LOADED_OFFICEES';
export const ADD_NEW_OFFICE = 'ADD_NEW_OFFICE';
export const GET_INVOICES = 'GET_INVOICES';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_LOADED = 'INVOICES_LOADED';
export const PRESCRIPTION_LOADING = 'PRESCRIPTION_LOADING';
export const PRESCRIPTION_LOADED = 'PRESCRIPTION_LOADED';
export const PRESCRIPTION_SUCCESS = 'PRESCRIPTION_SUCCESS';
export const PRESCRIPTION_SAVED = 'PRESCRIPTION_SAVED';
export const PRESCRIPTION_FAILED = 'PRESCRIPTION_FAILED';
export const PRESCRIPTION_LOADING_DATA = 'PRESCRIPTION_LOADING_DATA';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const RETRIEVE_OLD_OFFICE = 'RETRIEVE_OLD_OFFICE';
export const RETRIEVE_OLD_OFFICE_FAIL = 'RETRIEVE_OLD_OFFICE';
export const DELETE_OFFICE = 'DELETE_OFFICE';
export const OFFICE_DELETE_FAIL = 'OFFICE_DELETE_FAIL';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
export const LOADING_USERS = 'LOADING_USERS';
export const LOADED_USERS = 'LOADED_USERS';
export const LOADED_FAIL_USERS = 'LOADED_FAIL_USERS';
export const VERIFYING_USER = 'VERIFYING_USER';
export const VERIFIED_USER = 'VERIFIED_USER';
export const VERIFIED_FAIL_USER = 'VERIFIED_FAIL_USER';
export const OMITTING_USER = 'OMITTING_USER';
export const OMITTED_USERS = 'OMITTED_USERS';
export const OMITTED_FAIL_USERS = 'OMITTED_FAIL_USERS';
export const RESET = 'RESET';
export const LOADED_UL20_USERS = 'LOADED_UL20_USERS';
export const LOADED_FAIL_UL20_USERS = 'LOADED_FAIL_UL20_USERS';
export const LINK_BILLING_ACCOUNT_ID_SUCCESS =
  'LINK_BILLING_ACCOUNT_ID_SUCCESS';
export const LINK_BILLING_ACCOUNT_ID_FAILED = 'LINK_BILLING_ACCOUNT_ID_FAILED';
export const LINK_BILLING_ACCOUNT_ID_LOADING =
  'LINK_BILLING_ACCOUNT_ID_LOADING';
export const USER_MAKE_PREPAID_SUCCESS = 'USER_MAKE_PREPAID_SUCCESS';
export const USER_MAKE_PREPAID_FAILED = 'USER_MAKE_PREPAID_FAILED';
export const SHIPMENT_GETQBCUSTOMER_LOADING = 'SHIPMENT_GETQBCUSTOMER_LOADING';
export const SHIPMENT_GETQBCUSTOMER_SUCCESS = 'SHIPMENT_GETQBCUSTOMER_SUCCESS';
export const SHIPMENT_GETQBCUSTOMER_FAILED = 'SHIPMENT_GETQBCUSTOMER_FAILED';
export const SHIPMENT_PRINT_SHIPPING_LABEL_LOADING =
  'SHIPMENT_PRINT_SHIPPING_LABEL_LOADING';
export const SHIPMENT_PRINT_SHIPPING_LABEL_SUCCESS =
  'SHIPMENT_PRINT_SHIPPING_LABEL_SUCCESS';
export const SHIPMENT_PRINT_SHIPPING_LABEL_FAILED =
  'SHIPMENT_PRINT_SHIPPING_LABEL_FAILED';
export const SHIPMENT_MAIl_SHIPPING_LABEL_LOADING =
  'SHIPMENT_MAIl_SHIPPING_LABEL_LOADING';
export const SHIPMENT_MAIl_SHIPPING_LABEL_SUCCESS =
  'SHIPMENT_MAIl_SHIPPING_LABEL_SUCCESS';
export const SHIPMENT_MAIl_SHIPPING_LABEL_FAILED =
  'SHIPMENT_MAIl_SHIPPING_LABEL_FAILED';
export const SHIPMENT_RESET = 'SHIPMENT_RESET';
export const SHIPMENT_QBCUSTOMER_DATA = 'SHIPMENT_QBCUSTOMER_DATA';
export const SHIPMENT_PRINT_RETURN_LABEL_LOADING =
  'SHIPMENT_PRINT_RETURN_LABEL_LOADING';
export const SHIPMENT_PRINT_RETURN_LABEL_SUCCESS =
  'SHIPMENT_PRINT_RETURN_LABEL_SUCCESS';
export const SHIPMENT_PRINT_RETURN_LABEL_FAILED =
  'SHIPMENT_PRINT_RETURN_LABEL_FAILED';
export const SHIPMENT_LIST_FETCH_SUCCESS = 'SHIPMENT_LIST_FETCH_SUCCESS';
export const SHIPMENT_LIST_FETCH_FAILED = 'SHIPMENT_LIST_FETCH_FAILED';
export const SHIPMENT_LIST_FETCH_LOADING = 'SHIPMENT_LIST_FETCH_LOADING';
export const PRINTER_STATUS = 'PRINTER_STATUS';
export const PRINTER_DEFAULT = 'PRINTER_DEFAULT';
export const PRINTER_ERROR = 'PRINTER_ERROR';
export const PRINTER_RESET = 'PRINTER_RESET';
export const PRINTER_LOADING_TYPE = 'PRINTER_LOADING_TYPE';
export const VALIDATE_POSTAL_CODE_LOADING = 'VALIDATE_POSTAL_CODE_LOADING';
export const VALIDATE_POSTAL_CODE_SUCCESS = 'VALIDATE_POSTAL_CODE_SUCCESS';
export const VALIDATE_POSTAL_CODE_FAILED = 'VALIDATE_POSTAL_CODE_FAILED';
export const SHIPMENT_LIST_DELETE_SUCCESS = 'SHIPMENT_LIST_DELETE_SUCCESS';
export const SHIPMENT_LIST_DELETE_FAILED = 'SHIPMENT_LIST_DELETE_FAILED';
export const SHIPMENT_LIST_DELETE_LOADING = 'SHIPMENT_LIST_DELETE_LOADING';
export const SHIPMENT_LIST_DELETED_RESET = 'SHIPMENT_LIST_DELETED_RESET';
