import Cookies from 'universal-cookie';
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  EXISTING_USER_ACCT_RESET,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_EXISTING_CUSTOMER_FAIL,
  REGISTER_EXISTING_CUSTOMER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  RESET,
  USER_LOADED,
  USER_LOADING,
  AUTHENTICATION_RESET,
} from '../actions/types';

const cookies = new Cookies();

const storeTokenAndReturnProfile = (
  payload = { accessToken: '', permissions: [], roles: [] }
) => {
  cookies.set('token', payload.accessToken, { path: '/' });
  cookies.set('isAuthenticated', true, { path: '/' });
  const rolesPermission = {
    permissions: payload?.permissions,
    roles: payload?.roles,
  };
  localStorage.setItem('profile', JSON.stringify(rolesPermission));
  const profileObj = {
    profile: rolesPermission,
    token: payload.accessToken,
    isAuthenticated: true,
  };
  localStorage.setItem('loginTime', new Date());

  return profileObj;
};

const profileItem = JSON.parse(localStorage.getItem('profile'));

const initialState = {
  token: cookies.get('token'),
  isAuthenticated: cookies.get('isAuthenticated'),
  profile: {
    permissions: profileItem?.permissions || [],
    roles: profileItem?.roles || [],
  },
  isLoading: false,
  user: null,
  registered: false,
  registeredFailed: false,
  errorMessage: '',
};

function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case RESET:
      return {
        ...state,
        registered: false,
        registeredFailed: false,
        isLoading: false,
        errorMessage: '',
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        registered: false,
        registeredFailed: false,
        errorMessage: '',
      };
    case REGISTER_LOADING:
    case LOGIN_LOADING:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true,
        registeredFailed: false,
        registered: false,
        errorMessage: '',
      };
    case USER_LOADED:
      return {
        ...state,
        registered: false,
        registeredFailed: false,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
        errorMessage: '',
      };
    case REFRESH_TOKEN:
      const refreshPayload = storeTokenAndReturnProfile({
        accessToken: payload,
        permissions: state.profile.permissions,
        roles: state.profile.roles,
      });
      return {
        ...state,
        ...refreshPayload,
      };
    case LOGIN_SUCCESS:
    case REGISTER_EXISTING_CUSTOMER_SUCCESS:
      const loginPayload = storeTokenAndReturnProfile(payload);
      return {
        ...state,
        registered: false,
        registeredFailed: false,
        isLoading: false,
        errorMessage: '',
        ...loginPayload,
      };
    case REGISTER_SUCCESS:
      const registerPayload = storeTokenAndReturnProfile(payload);
      return {
        ...state,
        ...registerPayload,
        isLoading: false,
        registered: true,
        errorMessage: '',
      };
    case REGISTER_EXISTING_CUSTOMER_FAIL:
      return {
        ...state,
        isLoading: false,
        registered: false,
        token: null,
        user: null,
        isAuthenticated: null,
        registeredFailed: true,
        errorMessage: action.payload,
      };
    case LOGIN_FAIL:
    case EXISTING_USER_ACCT_RESET:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: null,
        isLoading: false,
        registered: false,
        registeredFailed: false,
      };
    case LOGOUT_SUCCESS:
      cookies.remove('token', { path: '/' });
      cookies.remove('isAuthenticated', { path: '/' });
      cookies.remove('user', { path: '/' });
      localStorage.removeItem('profile');
      localStorage.removeItem('loginTime');
      return state;
    case AUTH_ERROR:
    case REGISTER_FAIL:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        registeredFailed: payload,
      };
    case AUTHENTICATION_RESET:
      return initialState;
    default:
      return state;
  }
}

export default reducer;
